import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";


export default function MainPage({ children }) {

    return (
        <div className="max-w-5xl mx-auto sm:mt-16 px-5 lg:px-0">
            {children}
        </div>
    );
}
