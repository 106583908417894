import { useLayoutEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';

import WalletProtected from './layout/WalletProtected'
import Wallet from "./pages/Wallet";
import Claim from "./pages/Claim";
import Header from "./components/Header";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import ConnectWallet from "./pages/ConnectWallet";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

function App() {

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useLayoutEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Web3ReactProvider getLibrary={getLibrary} >
      <QueryClientProvider client={queryClient}>

        <BrowserRouter>
          <div className="min-w-screen min-h-screen bg-drbg bg-cover bg-fixed">

            <Routes>
              <Route path="/" element={<ConnectWallet dimensions={dimensions} />} />
              <Route path="/claim" element={<WalletProtected><Claim /></WalletProtected>} />
            </Routes>
          </div>
        </BrowserRouter>
      </QueryClientProvider>
    </Web3ReactProvider>
  );
}

export default App;
