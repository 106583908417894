

export default function Button({ children, clickFunction, }) {
    return (
        <button onClick={clickFunction}
            className={`inline-block text-[#3a1f3b] font-[900] my-2 px-4 xs:px-14 py-2
                    shadow-[0px_4px_3px_rgba(0,0,0,0.8)] uppercase
                    rounded-[25px] font-pressio-condensed w-full
                    bg-gradient-to-b from-[#FCC448] via-[#FBF374] via-[#FFD236] to-[#BD7628]
                    `}
        >
            {children}
        </button>
    )
}